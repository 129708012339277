<template>
  <div>
    <input v-model="emailAddress" type="email" placeholder="your@email.com" />
    <button @click="submitForm">{{subscribeText}}</button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: '5B5E7037DA78A748374AD499497E309E1AC6946A5D3DAB6D6C30AF2ED3E0254C006CA8D179646F04549392EF9944F3C403441D5DCD832E34C7A9960EA35D335A',
      emailName: 'cm-',
      emailAddress: 'myles@platocreative.co.nz',
      subscribeText: 'Subscribe'
    }
  },
  methods: {
    submitEmail(secureUrl) {
      const params = {}
      params[this.emailName] = this.emailAddress
      // Warning: This response sometimes triggers a recapture if repeat requests are made from the same ip
      return fetch(secureUrl, {
        method: 'POST',
        body: new URLSearchParams(params),
        headers: new Headers({
          'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
        })
      })
        .then(response => {    
            console.log(response);
                    
          this.subscribeText = 'Thanks!'
          setTimeout(() => {
            this.subscribeText = 'Subscribe'
          }, 5000)
        })
        .catch(error => {
          console.log(error)
          this.subscribeText = 'Error!'
          setTimeout(() => {
            this.subscribeText = 'Subscribe'
          }, 5000)
        })
    },
    generateSecureSubscribeLink(email) {
      const params = {
        email: this.emailAddress,
        data: this.id
      }
      return fetch('https://createsend.com//t/getsecuresubscribelink', {
        method: 'POST',
        body: new URLSearchParams(params),
        headers: new Headers({
          'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
        })
      })
        .then(function(response) {
            response.text()
        })
        .then(url => {
          this.submitEmail(url)
        })
        .catch(error => {
          console.log(error)
          this.subscribeText = 'Error!'
          setTimeout(() => {
            this.subscribeText = 'Subscribe'
          }, 5000)
        })
    },
    submitForm() {
      this.subscribeText = 'Please Wait...'
      this.generateSecureSubscribeLink()
    }
  }
}
</script>
<style lang="scss">
</style>