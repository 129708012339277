<template>
    <div id="popup" class="w-full fixed top-0 flex flex-col justify-center items-center">
        <PopupCloseButton></PopupCloseButton>
        <div class="h-screen overflow-y-auto w-full grid" style="grid-auto-rows: 1fr auto;"> <!-- Wrapper needed to scroll inside fixed element -->
            
            <div class="w-full h-screen lg:h-full mx-auto flex flex-col justify-none lg:justify-center md:max-w-xl lg:max-w-6xl">
                <h1 class="h1 popup-slogan text-center text-white mt-8 lg:mt-0 mb-10">Tomorrow's dairying, today</h1>

                <div class="h-full pb-10 lg:pb-0 lg:h-auto flex gap-5 lg:gap-10 flex-col lg:flex-row mx-8">
                    <PopupCTABox class="popup-first" :buttonText="'Continue to site'" :link="'/'">
                        <template v-slot:logo>
                            <component :is="currentsitename + 'Logo'"></component>
                        </template>
                    </PopupCTABox>
                    <PopupCTABox class="popup-last" :buttonText="'Go to site'" :link="othersiteurl">
                        <template v-slot:logo>
                            <component :is="otherSiteLogo()"></component>
                        </template>
                    </PopupCTABox>
                </div>
            </div>

            <PopupPartners></PopupPartners>
        </div>
    </div>
</template>

<script>
import SIDDCLogo from './SIDDC-Logo.vue'
import LUDFLogo from './LUDF-Logo.vue'
import PopupCloseButton from './PopupCloseButton.vue'
import PopupCTABox from './PopupCTABox.vue'
import PopupPartners from './PopupPartners.vue'

export default {
    name: 'Popup',
    props: {
        othersiteurl: String,
        currentsitename: String
    },
    components: {
        SIDDCLogo,
        LUDFLogo,
        PopupCloseButton,
        PopupCTABox,
        PopupPartners
    },
    methods: {
        chooseOption(link = false) {
            document.cookie = 'showPopup=false'
            if (!link) this.$parent.showPopup = document.cookie.includes('showPopup=true')
            else window.location = link
        },

        otherSiteLogo() {
            if (this.currentsitename === 'SIDDC') return 'LUDFLogo'
            return 'SIDDCLogo'
        }
    }
}
</script>

<style scoped>
#popup {
    z-index: 9999;
    backdrop-filter: blur(6px);
    background-color: rgba(0, 0, 0, 0.6);
}

.popup-first { border-top-left-radius: 4rem; }
.popup-last { border-bottom-right-radius: 4rem; }
.popup-slogan { font-size: 10vw; }

@media (min-width: 400px) {
    .popup-slogan { font-size: 11vw; }
}

@media (min-width: 768px) {
    .popup-slogan { font-size: 3rem; }
}
</style>
