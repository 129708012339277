import Vue from 'vue'
import Breakpoints from '../vue/breakpoints'
import CampaignForm from "../vue/CampaignForm.vue"
import Popup from "../vue/Popup"
import LazyLoad from "vanilla-lazyload";
import MmenuLight from  '../../node_modules/mmenu-light/src/mmenu-light.js';

// Intiate Vuejs instance
var app = new Vue({
    el: '#app',
    data() { 
        return {
            showPopup: false
        }
    },
    watch: {
        showPopup: {
            handler(newVal) {
                if (newVal === true) {
                    document.body.style.overflowY = 'hidden'
                } else {
                    document.body.style.overflowY = ''
                }
            }
        }
    },
    created() {
        if (location.search.includes('popup=false')) {
            document.cookie = 'showPopup=false'
        } else if (!document.cookie.includes('showPopup')) {
            document.cookie = 'showPopup=true'
        }
        this.showPopup = document.cookie.includes('showPopup=true')
    },
    delimiters: ["${","}"],
    components: {
        Breakpoints,
        CampaignForm,
        Popup
    }
})

// Scripts to fire once dom has loaded
document.addEventListener("DOMContentLoaded", () => {
    
    // Lazyload
    var lazy = new LazyLoad({
        elements_selector: ".lazy"
    });

    //Adding right arrow to the contact submit btn
    let contactSubmit = document.querySelector('.contactsubmitted');
    if(contactSubmit != null) {
        contactSubmit.innerHTML = 'Submit <i class="far fa-arrow-right ml-2"></i>';
    }
   
    //Mobile menu init // Sync search and menu open
    const menu = new MmenuLight( document.querySelector( "#mobile-menu" ), "(max-width: 1024px)" );
    const navigator = menu.navigation();
    const drawer = menu.offcanvas();

    // Open Menu
    const navTrigger = document.querySelector('.js-open-nav');
    const projectWrapper = document.getElementById('app');

    const message = document.querySelector('.js-message');

    // Toggle Menu
    bindEvent(navTrigger, "click", function() {
        this.classList.toggle('is-active');
        projectWrapper.classList.toggle('js-is-open');
        message.classList.add('hidden');

        if (this.classList.contains('is-active')) {
            drawer.open();
        } else {
            drawer.close();
        }

    }, false);
    
    //Adding class to link inside the pop up text
    const popUpAnchors = document.querySelectorAll('.js-message-text a');
    console.log(popUpAnchors);
    popUpAnchors.forEach(anchor => {
        anchor.classList.add('block', 'md:inline-block', 'underline');
    });
    
    // Close message
    const close = document.querySelector('.js-close-message');
    if(localStorage.getItem("popUpStatus") == null) {
        localStorage.setItem("popUpStatus", "Not Closed");
    }
    
    bindEvent(close, "click", function() {
        localStorage.setItem("popUpStatus", "Closed");
        message.classList.add('hidden');
    }, false);

    // const message = document.querySelector('.js-message');
    // Setting session variable to set pop based on session
    if(localStorage.getItem("popUpStatus") == "Not Closed"){
        message.classList.remove('hidden');
    } else {
        message.classList.add('hidden');
    };
    
    // Accordions
    const accordionItems = document.querySelectorAll('[data-accordion-item]');
    const accordionContentPanes = document.querySelectorAll('[data-accordion-content]');
    if (accordionItems.length) {
        accordionItems.forEach(function(accordion) {
            const accordionTitleRow = accordion.firstElementChild;
            bindEvent(accordionTitleRow, 'click', toggleAccordion);
        });
    } 

    function toggleAccordion(e) {
    
        if(e.target.parentElement.classList.contains('accordion-item--active')) {
            e.target.parentElement.classList.remove('accordion-item--active');
            return;
        }
        accordionContentPanes.forEach(function(content) {
            if (content.previousElementSibling === e.target) {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.classList.remove('accordion-item--active');
                }
                content.parentElement.classList.add('accordion-item--active');
            } else {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.parentElement.classList.remove('accordion-item--active');
                }
                content.classList.add('accordion-item--active');
            }
        });
    }

    function bindEvent(el, eventName, eventHandler) {
        if (el.addEventListener){
            el.addEventListener(eventName, eventHandler, false);
        } else if (el.attachEvent){
            el.attachEvent('on'+eventName, eventHandler);
        }
    }

})