<template>
    <div class="bg-white h-full w-full flex flex-col justify-evenly lg:justify-between gap-4 md:gap-10 h-auto px-10 py-5 md:px-16 md:py-16">
        <div><slot name="logo"></slot></div>
        <div class="w-full flex justify-center">
            <a @click.prevent="$parent.chooseOption(link)" class="w-full md:w-2/3 flex items-center justify-center bg-black text-white rounded-full py-4 text-lg md:text-xl"
            :href="link">
                <span class="font-semibold">{{buttonText}}</span><i class="less-font-weight ml-4 fas fa-arrow-right"></i>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PopupCTABox',
    props: {
        buttonText: String,
        link: String
    }
}
</script>

<style scoped>
.less-font-weight {
    font-weight: 400 !important;
}
</style>
